import React, { Component } from 'react';

class About extends Component {
    render() {
        return(
            <div>
                <h1>About me page</h1>
            </div>
        );
    }
}

export default About;