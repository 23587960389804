import React, { Component } from 'react';
import { Tabs, Tab, Grid, Cell, Card, CardTitle, CardText } from 'react-mdl';
import TodoImg from '../img/Todo-List.PNG';
import ItineraireImg from '../img/itineraire.png';
import { Button, Modal } from 'react-bootstrap'
import FPLogo from '../img/FPLogo.bmp';
import LogoBarbecue from '../img/LogoBarbecueFrance.png';
import chatNode from '../img/chatNode.png';

class Projects extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow2 = this.handleShow2.bind(this);
        this.handleClose2 = this.handleClose2.bind(this);
        this.handleShow3 = this.handleShow3.bind(this);
        this.handleClose3 = this.handleClose3.bind(this);
        this.handleShow4 = this.handleShow4.bind(this);
        this.handleClose4 = this.handleClose4.bind(this);
        this.handleShow5 = this.handleShow5.bind(this);
        this.handleClose5 = this.handleClose5.bind(this);
        this.handleShow6 = this.handleShow6.bind(this);
        this.handleClose6 = this.handleClose6.bind(this);

        this.state = {
            activeTab: 0,
            show: false,
            show2: false,
            show3: false,
            show4: false,
            show5: false,
            show6: false
        };
    }

    handleClose() {
        this.setState({ show: false });
    }
    
    handleShow() {
        this.setState({ show: true });
    }

    handleClose2() {
        this.setState({ show2: false });
    }
    
    handleShow2() {
        this.setState({ show2: true });
    }

    handleClose3() {
        this.setState({ show3: false });
    }
    
    handleShow3() {
        this.setState({ show3: true });
    }

    handleClose4() {
        this.setState({ show4: false });
    }
    
    handleShow4() {
        this.setState({ show4: true });
    }

    handleClose5() {
        this.setState({ show5: false });
    }
    
    handleShow5() {
        this.setState({ show5: true });
    }

    handleClose6() {
        this.setState({ show6: false });
    }
    
    handleShow6() {
        this.setState({ show6: true });
    }

    toggleCategories() {
        if(this.state.activeTab === 0) {
            return(
                <div className="projects-grid">

                    {/* Project 1 */}
                    <button variant="primary" onClick={this.handleShow} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="todoProjects" style={{color: 'black', height: '176px', backgroundImage: `url(${TodoImg})`, backgroundRepeat: 'no-repeat'}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">To do List ReactJS</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>To do List ReactJS</Modal.Title>
                    </Modal.Header>
                    <img src={TodoImg} alt="To do list" className="imgModal" />
                    <Modal.Body>
                        <p>
                            To do List développée en ReactJS lors de mon apprentissage en autodidacte. Cette application permet d'ajouter et de supprimer
                            des éléments dans une liste de tâches.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 2 */}
                    <button variant="primary" onClick={this.handleShow2} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${ItineraireImg})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Itinéraire Normandie</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show2} onHide={this.handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Itinéraire Normandie</Modal.Title>
                    </Modal.Header>
                    <img src={ItineraireImg} alt="Itinéraire Normandie" className="imgModal" />
                    <Modal.Body>
                        <p>
                            Itinéraire Normandie est une application web développée lors de mon Bachelor Chef de Projet en Marketing Internet et Conception
                            de Sites. Cette application permettait de générer automatiquement sur une carte un itinéraire adapté en fonction des lieux
                            de visite choisis en Normandie. Les différents lieux à visiter étaient classés par époque, si l'on choisissait l'époque
                            contemporaine, une liste de musées ou de lieux historiques apparaissait et il suffisait de les cocher et de valider pour qu'un
                            itinéraire soit tracé. Il y avait ensuite la possibilité de créer un compte pour enregistrer ses itinéraires ainsi que de
                            partager son itinéraire à la communauté Itinéraire Normandie ou sur les réseaux sociaux.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose2}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 3 */}
                    <button variant="primary" onClick={this.handleShow3} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${FPLogo})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">France-Pellets</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show3} onHide={this.handleClose3}>
                    <Modal.Header closeButton>
                        <Modal.Title>France-Pellets</Modal.Title>
                    </Modal.Header>
                    <img src={FPLogo} alt="Logo France-Pellets" className="imgModal" />
                    <Modal.Body>
                        <p>
                            France-Pellets est l'entreprise dans laquelle j'ai effectué mon alternance pour mon bachelor Chef de Projet en 
                            Marketing Internet et Conception de Sites. L'une de mes missions pendant les périodes en entreprise était la refonte
                            de leur site vitrine <a href="http://france-pellets.com" rel="noopener noreferrer" target="_blank">france-pellets.com</a>.
                            Leur ancien site était développé à l'aide de la technologie flash qui est aujourd'hui obsolète. Ma mission était donc de
                            le refaire mais il devait pouvoir être modifié par des personnes de l'entreprise qui ne connaissaient pas de langage de
                            programmation. J'ai donc utilisé le CMS WordPress pour développer ce site car il est très facile d'utilisation. Pour le
                            côté esthétique du site, je n'avait pas d'autre choix que de reprendre la charte graphique de l'ancien site car il ne
                            fallait pas que les habitués du site se retrouve sans repère en arrivant sur le nouveau site.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose3}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 4 */}
                    <button variant="primary" onClick={this.handleShow4} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${LogoBarbecue})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Barbecue-France</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show4} onHide={this.handleClose4}>
                    <Modal.Header closeButton>
                        <Modal.Title>Barbecue-France</Modal.Title>
                    </Modal.Header>
                    <img src={LogoBarbecue} alt="Logo Barbecue-France" className="imgModal" />
                    <Modal.Body>
                        <p>
                            Barbecue-France est un site e-commerce auquel j'ai participé au développement. Ce site appartient a l'entreprise
                            France-Pellets dans laquelle j'ai effectué mon alternance lors de mon Bachelor. C'est un site de vente de barbecue à pellets
                            réalisé à l'aide de la solution Oxatis. Sur ce site, j'ai choisi la charte graphique, fait le logo et réalisé tout le SEO
                            (référencement naturel).
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose4}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 5 */}
                    <button variant="primary" onClick={this.handleShow5} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${chatNode})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Chat NodeJS</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show5} onHide={this.handleClose5}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chat NodeJS</Modal.Title>
                    </Modal.Header>
                    <img src={chatNode} alt="Chat NodeJS" className="imgModal" />
                    <Modal.Body>
                        <p>
                            J'ai développé ce chat en NodeJS lors d'un test pour intégrer une entreprise. Cela m'a permis d'en apprendre plus sur NodeJS
                            que je n'avais jamais manipulé avant.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose5}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 6 */}
                    <button variant="primary" onClick={this.handleShow6} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px'}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Téléassistance</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show6} onHide={this.handleClose6}>
                    <Modal.Header closeButton>
                        <Modal.Title>Téléassistance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            L'application web de téléassistance était un projet réalisé lors de mon BTS Systèmes Numériques. Ce projet réalisé en HTML,
                            CSS, JavaScript, PHP et Java en équipe de 4 personnes permettait de diffuser en direct la caméra d'une tablette sur l'application web.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose6}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
            );
        } else if(this.state.activeTab === 1) {
            return (
                <div className="projects-grid">
                    {/* Project 3 */}
                    <button variant="primary" onClick={this.handleShow3} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${FPLogo})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">France-Pellets</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show3} onHide={this.handleClose3}>
                    <Modal.Header closeButton>
                        <Modal.Title>France-Pellets</Modal.Title>
                    </Modal.Header>
                    <img src={FPLogo} alt="Logo France-Pellets" className="imgModal" />
                    <Modal.Body>
                        <p>
                            France-Pellets est l'entreprise dans laquelle j'ai effectué mon alternance pour mon bachelor Chef de Projet en 
                            Marketing Internet et Conception de Sites. L'une de mes missions pendant les périodes en entreprise était la refonte
                            de leur site vitrine <a href="http://france-pellets.com" rel="noopener noreferrer" target="_blank">france-pellets.com</a>.
                            Leur ancien site était développé à l'aide de la technologie flash qui est aujourd'hui obsolète. Ma mission était donc de
                            le refaire mais il devait pouvoir être modifié par des personnes de l'entreprise qui ne connaissaient pas de langage de
                            programmation. J'ai donc utilisé le CMS WordPress pour développer ce site car il est très facile d'utilisation. Pour le
                            côté esthétique du site, je n'avait pas d'autre choix que de reprendre la charte graphique de l'ancien site car il ne
                            fallait pas que les habitués du site se retrouve sans repère en arrivant sur le nouveau site.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose3}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
            );         
        } else if(this.state.activeTab === 2) {
            return(
                <div className="projects-grid">
                    {/* Project 4 */}
                    <button variant="primary" onClick={this.handleShow4} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${LogoBarbecue})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Barbecue-France</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show4} onHide={this.handleClose4}>
                    <Modal.Header closeButton>
                        <Modal.Title>Barbecue-France</Modal.Title>
                    </Modal.Header>
                    <img src={LogoBarbecue} alt="Logo Barbecue-France" className="imgModal" />
                    <Modal.Body>
                        <p>
                            Barbecue-France est un site e-commerce auquel j'ai participé au développement. Ce site appartient a l'entreprise
                            France-Pellets dans laquelle j'ai effectué mon alternance lors de mon Bachelor. C'est un site de vente de barbecue à pellets
                            réalisé à l'aide de la solution Oxatis. Sur ce site, j'ai choisi la charte graphique, fait le logo et réalisé tout le SEO
                            (référencement naturel).
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose4}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
            );
        } else {
            return(
                <div className="projects-grid">
                    {/* Project 1 */}
                    <button variant="primary" onClick={this.handleShow} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="todoProjects" style={{color: 'black', height: '176px', backgroundImage: `url(${TodoImg})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Todo List ReactJS</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>To do List ReactJS</Modal.Title>
                    </Modal.Header>
                    <img src={TodoImg} alt="To do list" className="imgModal" />
                    <Modal.Body>
                        <p>
                            To do List développée en ReactJS lors de mon apprentissage en autodidacte. Cette application permet d'ajouter et de supprimer
                            des éléments dans une liste de tâches.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 2 */}
                    <button variant="primary" onClick={this.handleShow2} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${ItineraireImg})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Itinéraire Normandie</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show2} onHide={this.handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Itinéraire Normandie</Modal.Title>
                    </Modal.Header>
                    <img src={ItineraireImg} alt="Itinéraire Normandie" className="imgModal" />
                    <Modal.Body>
                        <p>
                            Itinéraire Normandie est une application web développée lors de mon Bachelor Chef de Projet en Marketing Internet et Conception
                            de Sites. Cette application permettait de générer automatiquement sur une carte un itinéraire adapté en fonction des lieux
                            de visite choisis en Normandie. Les différents lieux à visiter étaient classés par époque, si l'on choisissait l'époque
                            contemporaine, une liste de musées ou de lieux historiques apparaissait et il suffisait de les cocher et de valider pour qu'un
                            itinéraire soit tracé. Il y avait ensuite la possibilité de créer un compte pour enregistrer ses itinéraires ainsi que de
                            partager son itinéraire à la communauté Itinéraire Normandie ou sur les réseaux sociaux.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose2}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 5 */}
                    <button variant="primary" onClick={this.handleShow5} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px', backgroundImage: `url(${chatNode})`}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Chat NodeJS</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show5} onHide={this.handleClose5}>
                    <Modal.Header closeButton>
                        <Modal.Title>Chat NodeJS</Modal.Title>
                    </Modal.Header>
                    <img src={chatNode} alt="Chat NodeJS" className="imgModal" />
                    <Modal.Body>
                        <p>
                            J'ai développé ce chat en NodeJS lors d'un test pour intégrer une entreprise. Cela m'a permis d'en apprendre plus sur NodeJS
                            que je n'avais jamais manipulé avant.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose5}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>

                    {/* Project 6 */}
                    <button variant="primary" onClick={this.handleShow6} style={{minWidth: '450'}} href="#">
                        <Card className="cardProject" shadow={5}>
                            <CardTitle className="projects" style={{color: 'black', height: '176px'}}>
                            </CardTitle>
                            <CardText>
                            <h2 className="projectsTitle">Téléassistance</h2>
                            </CardText>
                            {/*<CardMenu style={{color: '#000000'}}>
                                <IconButton name="share" />
                            </CardMenu>*/}
                        </Card>
                    </button>

                    <Modal show={this.state.show6} onHide={this.handleClose6}>
                    <Modal.Header closeButton>
                        <Modal.Title>Téléassistance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            L'application web de téléassistance était un projet réalisé lors de mon BTS Systèmes Numériques. Ce projet réalisé en HTML,
                            CSS, JavaScript, PHP et Java en équipe de 4 personnes permettait de diffuser en direct la caméra d'une tablette sur l'application web.
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose6}>
                        Fermer
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </div>
            );
        }
    }

    render() {
        return(
            <div className="category-tabs">
                <Tabs activeTab={this.state.activeTab} onChange={(tabId) => this.setState({ activeTab: tabId})} ripple>
                    <Tab>Tout</Tab>
                    <Tab>Site Vitrine</Tab>
                    <Tab>Site E-commerce</Tab>
                    <Tab>Applications WEB</Tab>
                </Tabs>

                <Grid>
                    <Cell col={12}>
                    <div className="content">{this.toggleCategories()}</div>
                    </Cell>
                </Grid>
            </div>
        );
    }
}

export default Projects;