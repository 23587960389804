import React, { Component } from 'react';
import { Grid, Cell, List, ListItem, ListItemContent } from 'react-mdl';
import Img from '../img/photo.png';


class Contact extends Component {
    render() {
        return(
            <div className="contact-body">
                <Grid className="contact-grid">
                    <Cell col={6}>
                        <h2>Pierre Joly</h2>
                        <img 
                            src={Img}
                            alt="Avatar"
                            style={{height: '250px'}}
                        />
                        <p style={{ width: '75%', margin: 'auto', paddingTop: '1em'}}></p>
                    </Cell>
                    <Cell col={6}>
                        <h2>Contactez-moi</h2>
                        <hr/>

                        <div className="contact-list">
                            <List>
                                <ListItem>
                                    <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                                            <i className="fas fa-phone-square" aria-hidden="true"> </i>
                                            06 61 96 64 63
                                    </ListItemContent>
                                </ListItem>

                                <ListItem>
                                    <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                                                <i className="fas fa-envelope" aria-hidden="true"> </i>
                                                <span className="mail">jolypierre1@wanadoo.fr</span>
                                    </ListItemContent>
                                </ListItem>

                                <ListItem>
                                    <ListItemContent style={{fontSize: '30px', fontFamily: 'Anton'}}>
                                            <i className="fab fa-linkedin" aria-hidden="true"> </i>
                                            <a href="https://www.linkedin.com/in/pierre-joly-b704a0151/" rel="noopener noreferrer" target="_blank">Pierre Joly</a>
                                    </ListItemContent>
                                </ListItem>
                            </List>
                        </div>        
                    </Cell>
                </Grid>
            </div>
        );
    }
}

export default Contact;