import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Formation from './formation';
import Img from '../img/photo.png';
import Experience from './experience';
import Skills from './skills';

class Resume extends Component {
    render() {
        return(
            <div>
                <Grid>
                    <Cell col={4}>
                        <div style={{textAlign: 'center'}}>
                            <img 
                                src={Img}
                                alt="avatar"
                                style={{height: '200px'}}
                            />
                        </div>
                        <h2 style={{paddingTop: '2em'}}>Pierre Joly</h2>
                        <h4 style ={{color: 'grey'}}>Développeur Front-End</h4>
                        <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                        <p>À l'issue de mon Bachelor Chef de Projet en Marketing Internet et Conception de Site, je suis en mesure d'assurer le développement front-end 
                            d'un site web (HTML/CSS, Bootstrap, JavaScript, ReactJS, NodeJS). Le Bachelor que j'ai effectué en alternance ainsi que mon BTS m'ont permis
                            d'acquérir des compétences comme la capacité à travailler en groupe et à valoriser les compétences de chaque membre. Suite à ces expériences,
                            je suis actuellement à la recherche d'un emploi à plein temps en tant que développeur web front-end.
                        </p>
                        <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                        <h5>Téléphone</h5>
                        <p>06 61 96 64 63</p>
                        <h5>Email</h5>
                        <p>jolypierre1@wanadoo.fr</p>
                        <h5>Web</h5>
                        <a id="myWebSite" href="https://pierrejoly.fr" target="_blank">pierrejoly.fr</a>
                        <hr style={{borderTop: '3px solid #833fb2', width: '50%'}}/>
                    </Cell>
                    <Cell className="resume-right-col" col={8}>

                        <h2>Expérience</h2>
                        <Experience 
                            startMonth='Mars'
                            startYear={2018}
                            endMonth='Octobre'
                            endYear={2018}
                            entrepriseName='France-Pellets'
                            jobName='Développeur Web'
                            jobDescription="Développement de 2 sites vitrines ainsi que la participation au développement d'un site e-commerce à l'aide de la solution Oxatis."
                        />

                        <Experience 
                            startMonth='Août'
                            startYear={2017}
                            endMonth='Septembre'
                            endYear={2017}
                            entrepriseName='CODAH'
                            jobName='Gestionnaire DT/DICT'
                            jobDescription="Gestion des demandes de travaux au sein du service des eaux et assainissements de la CODAH."
                        />

                        <Experience 
                            startMonth='Août'
                            startYear={2016}
                            endMonth='Septembre'
                            endYear={2016}
                            entrepriseName='CODAH'
                            jobName='Gestionnaire DT/DICT'
                            jobDescription="Gestion des demandes de travaux au sein du service des eaux et assainissements de la CODAH."
                        />

                        <Experience 
                            startMonth='Mai'
                            startYear={2016}
                            endMonth='Juin'
                            endYear={2016}
                            entrepriseName='CODAH'
                            jobName='Programmeur'
                            jobDescription="Programmation en LISP et VBScript d'un programme permettant de générer et de classer 
                            automatiquement des cartes des réseaux d'eau et assainissement du Havre et des villes alentours."
                        />

                        <Experience 
                            startMonth='Juin'
                            startYear={2014}
                            endMonth='Janvier'
                            endYear={2015}
                            entrepriseName='SNCF'
                            jobName='Électrotechnicien'
                            jobDescription="Maintenance électrique dans les gares et les bâtiments appartenant à la SNCF."
                        />
                        <hr style={{borderTop: '3px solid #e22947'}} />
                        <h2>Compétences</h2>
                        <Skills 
                            skill='HTML/CSS'
                            progress={95}
                        />

                        <Skills 
                            skill='JavaScript'
                            progress={80}
                        />

                        <Skills 
                            skill='ReactJS'
                            progress={75}
                        />

                        <Skills 
                            skill='NodeJS'
                            progress={60}
                        />
                        <hr style={{borderTop: '3px solid #e22947'}} />
                        <h2>Formations</h2>
                        <Formation 
                            startYear={2017}
                            endYear={2018}
                            schoolDescription='BACHELOR Chef de Projet en Marketing Internet et Conception de Site'
                            schoolName='FIM CCI FORMATION à Saint-Lô'
                        />

                        <Formation 
                            startYear={2015}
                            endYear={2017}
                            schoolDescription='BTS Systèmes Numériques'
                            schoolName='Lycée Raymond Queneau à Yvetôt'
                        />

                        <Formation 
                            startYear={2013}
                            endYear={2015}
                            schoolDescription='Bac Professionnel ELEEC'
                            schoolName='Lycée Raymond Queneau à Yvetôt'
                        />
                        <hr style={{borderTop: '3px solid #e22947'}} />

                    </Cell>
                </Grid>
            </div>
        );
    }
}

export default Resume;