import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Img from '../img/photo.png';
import CV from '../img/CV-Pierre-JOLY.pdf';

class Landing extends Component {
    render() {
        return(
            <div style={{width: '100%', margin: 'auto'}}>
                <Grid className="landing-grid">
                    <Cell col={12}>
                        <img 
                            src={Img}
                            alt="Avatar"
                            className="avatar-image"
                        />

                        <div className="dlCV">
                            <a href={CV} download="CV-Pierre-JOLY.pdf">
                                Télécharger mon CV <i class="fas fa-download"></i>
                            </a>
                        </div>
                        

                        <div className="banner-text">
                            <h1>Développeur Web Front-End</h1>
                        
                            <hr/>

                            <p>HTML/CSS | Bootstrap | JavaScript | ReactJS | NodeJS | Git</p>

                            <div className="social-links">

                                {/* LinkedIn */}
                                <a href="https://www.linkedin.com/in/pierre-joly-b704a0151/" rel="noopener noreferrer" target="_blank">
                                    <i className="fab fa-linkedin" aria-hidden="true" />
                                </a>
                            </div>
                        </div>
                    </Cell>
                </Grid>
            </div>
        );
    }
}

export default Landing;