import React, { Component } from 'react';
import './App.css';
import { Layout, Header, Navigation, Drawer, Content } from 'react-mdl';
import Main from './components/main';
import { Link } from 'react-router-dom';

class App extends Component {
  render() {
    return (
      <div className="demo-big-content">
        <Layout>
            <Header className="header-color" title={<Link className="home" to="/">Accueil</Link>} scroll>
                <Navigation>
                    <Link className="menu" to="/resume">CV en Ligne</Link>
                    <Link className="menu" to="/projects">Portfolio</Link>
                    <Link className="menu" to="/contact">Contact</Link>
                </Navigation>
            </Header>
            <Drawer title={<Link style={{textDecoration: 'none', color: 'black'}}
            to="/">Accueil</Link>}>
                <Navigation>
                    <Link to="/resume">CV en Ligne</Link>
                    <Link to="/projects">Portfolio</Link>
                    <Link to="/contact">Contact</Link>
                </Navigation>
            </Drawer>
            <Content>
                <div className="page-content" />
                <Main />
            </Content>
        </Layout>
      </div>
    );
  }
}

export default App;
